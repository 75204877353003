import React, { Component } from "react";
import LoginComponent from "./loginComponent";
import Grid from '@material-ui/core/Grid';
import AppUpdate from "../AppUpdate";


class unAuthorizedComponent extends Component {
    render() {
        return (
            <div className="login-container" >
                <div className="update-container">
                    <AppUpdate />
                </div>
                <Grid container className="login-grid">
                    <Grid item lg={4} sm={2}></Grid>
                    <Grid item lg={4} sm={8}>
                        <LoginComponent />
                    </Grid>
                    <Grid item lg={4} sm={2}></Grid>
                </Grid>

            </div>
        );

    }

}

export default unAuthorizedComponent; 