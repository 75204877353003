import React, { Component } from "react";
import { ExecuteLayoutEventMethods } from "../../../General/commonFunctions";
import PureJSComponentFactory from "../../Pages/Factory/pureJSComponentFactory";

class FactsClickRecognizer extends Component {

    async handleClick() {
        await ExecuteLayoutEventMethods(this.props.whenclick);

    }

    render() {
        let scaff = new PureJSComponentFactory().scaffoldComponent(this.props.layout);

        return (
            <div  {...this.props} onClick={this.handleClick.bind(this)}>
                {scaff}
            </div>
        );
    }
}

export default FactsClickRecognizer; 