import store from "../AppRedux/store";
import { storeDataset, storeDatasetField } from "../AppRedux";
import { pageServiceInstance, authServiceInstance } from "./globals";
import { mergeObjectDataset, showAppBusy, hideAppBusy } from "../AppRedux/pageDs/pageDsActions";
import { startOfToday } from "date-fns";
import { endOfToday } from "date-fns/esm";
import { funcs, dyadicFuncExecutor } from "./funcExecutor";
import { ShowAppMenu, ShowAppNotification } from "./globalFunctions";

export async function _loadProcDataToDataSet(serverDataset, eventInfo, callBackFunction) {

    if (serverDataset && eventInfo) {
        if (eventInfo.dset) {
            if (eventInfo.column && serverDataset?.length > 0) { //Select JSON Data stored in a Column in Dataset; Check the PopupLayout Info Scaffold JSON; It returns the whole dataset; But we need only JSON stored in layoutinfo column
                let columnValue = serverDataset[0][eventInfo.column];
                if (!columnValue) return;
                try {
                    serverDataset = JSON.parse(columnValue);
                }
                catch{
                    return;
                }
            }
            let storeDataSet = store.getState()[eventInfo.dset];
            let currentDataSet = storeDataSet;
            if (eventInfo.section && currentDataSet) currentDataSet = currentDataSet[eventInfo.section];    //If section name is provided in event consider it as object of multiple datasets; usually for popups
            let dstr1 = JSON.stringify(currentDataSet),
                dstr2 = JSON.stringify(serverDataset);  //Just to compare the exisiting Redux Object and new Object; Why shuld we change state if no changes

            if (dstr1 !== dstr2) {
                if (eventInfo.section) {
                    if (eventInfo.dset === "local") {
                        localStorage.setItem(eventInfo.section, dstr2);
                    }
                    else if (eventInfo.dset === "session") {
                        sessionStorage.setItem(eventInfo.section, dstr2);
                    }
                    else {
                        store.dispatch(storeDataset( //Redux
                            {
                                dataSetName: eventInfo.dset,
                                data: { ...storeDataSet, ...{ [eventInfo.section]: serverDataset } }
                            }
                        ));
                    }
                }
                else {

                    store.dispatch(storeDataset( //Redux
                        {
                            dataSetName: eventInfo.dset,
                            data: serverDataset
                        }
                    ));
                }
            }

            if (callBackFunction) callBackFunction(serverDataset);
        }

    }
}

export function SetCreateDataSet(dataInfo, dataObject) {
    if (dataInfo) {
        store.dispatch(storeDataset( //Redux
            {
                dataSetName: dataInfo.dset,
                data: dataObject
            }
        ));
    }
}

export function MergeDataSet(dataInfo, dataObject) {
    if (dataInfo) {
        store.dispatch(mergeObjectDataset( //Redux
            {
                dataSetName: dataInfo.dset,
                data: dataObject
            }
        ));
    }
}

export function SetCreateDataSetField(dataInfo) {
    if (dataInfo) {
        let originalData = dataInfo.data;
        let fieldName = dataInfo.fieldname;
        if (dataInfo.fieldname?.includes(".")) {    //Handling deep value changing
            let fieldArrays = dataInfo.fieldname.split(".");
            if (fieldArrays.length > 0) {
                originalData = store.getState()[dataInfo.dset][fieldArrays[0]];
                originalData = { ...originalData };
                let deepValue = originalData;
                let i = 1;
                for (; i < fieldArrays.length - 1; i++) {
                    deepValue = originalData[fieldArrays[i]];
                }
                fieldName = fieldArrays[0];
                deepValue[fieldArrays[i]] = dataInfo.data;
            }
        }

        store.dispatch(storeDatasetField( //Redux
            {
                dataSetName: dataInfo.dset,
                fieldName: fieldName,
                data: originalData
            }
        ));
    }
}

export function objectMatchAll(dataset, verifyArgs, satisfy) { //Verify single Object value pairs against another single Object and returns true if all property values match 
    let assertedMatches = [];
    if (verifyArgs) {
        Object.keys(verifyArgs).forEach(item => {
            let firstValue = GetControlPropertyFromStoreOrRefData(item, dataset);
            let secondValue = GetControlPropertyFromStoreOrRefData(verifyArgs[item], dataset);
            assertedMatches.push(firstValue === secondValue);
        });
    }
    let isAllMatched =
        satisfy === "any" ? assertedMatches.some(t => t === true)
            : assertedMatches.every(t => t === true);
    return isAllMatched;
}

function verifyDatasetAgainstMultipleValues(dataset, verifyArgs) {
    let shouldHaltExecution = false;

    let isAllMatched = objectMatchAll(dataset, verifyArgs?.ref, verifyArgs?.satisfy);

    if (verifyArgs?.halton === "match" && isAllMatched) {
        ExecuteLayoutEventMethods(verifyArgs?.whenmatch, dataset);
        shouldHaltExecution = true;
    }
    if (verifyArgs?.halton === "unmatch" && !isAllMatched) {
        ExecuteLayoutEventMethods(verifyArgs?.whenunmatch, dataset);
        shouldHaltExecution = true;
    }
    return shouldHaltExecution;

}

function getProcessedArgsWithGeoLocation(args, refData) {
    let mProcessedArgs = getProcessedArgs(
        {
            geolat: "[local.devloc.lat]",
            geolong: "[local.devloc.long]",
            ...args
        }
        , refData);
    return mProcessedArgs;
}

export async function ExecuteLayoutEventMethods(eventData, refData, callBackFunction = null) {
    let eData = GetControlPropertyFromStoreOrRefData(eventData, refData);
    if (typeof eData == "string") eData = JSON.parse(eData);
    // store.dispatch(showAppBusy());
    if (eData) {

        if (eData[0]?.exec !== "hideloader") store.dispatch(showAppBusy());
        for (let dataPoint of eData) {

            switch (dataPoint?.exec) {
                case "filldataset":
                    let sProcessedArgs = getProcessedArgsWithGeoLocation(dataPoint?.args?.args, refData);
                    let sServerDataSet = await pageServiceInstance.loadData(dataPoint?.args?.proc, sProcessedArgs);

                    if (verifyDatasetAgainstMultipleValues(sServerDataSet, dataPoint?.args?.verify)) {
                        store.dispatch(hideAppBusy());
                        return;
                    }


                    await _loadProcDataToDataSet(sServerDataSet, dataPoint?.args, callBackFunction);
                    break;
                case "fillmultidataset":
                    let mProcessedArgs = getProcessedArgsWithGeoLocation(dataPoint?.args?.args, refData);
                    let mServerDataset = await pageServiceInstance.loadData(dataPoint?.args?.proc, mProcessedArgs, "multi");

                    if (verifyDatasetAgainstMultipleValues(mServerDataset, dataPoint?.args?.verify)) {
                        store.dispatch(hideAppBusy());
                        return;
                    }

                    for (let mdset of (dataPoint?.args?.dsets ?? [])) {
                        await _loadProcDataToDataSet(mServerDataset[mdset?.table],
                            {
                                "dset": mdset?.name,
                                "proc": dataPoint?.args?.proc,
                                "column": mdset?.column,
                                "section": mdset?.section
                            }, callBackFunction);
                    }
                    break;
                case "setdataset":
                    // store.dispatch(showAppBusy());
                    let processedArgsData = getProcessedArgs(dataPoint?.args?.data, refData);
                    SetCreateDataSet(dataPoint?.args, processedArgsData);
                    // store.dispatch(hideAppBusy());
                    break;
                case "mergedataset":
                    // store.dispatch(showAppBusy());
                    let processedArgsData2 = getProcessedArgs(dataPoint?.args?.data, refData);
                    MergeDataSet(dataPoint?.args, processedArgsData2);
                    // store.dispatch(hideAppBusy());
                    break;
                case "setdatasetfield":
                    let processedArgsData3 = getProcessedArgs(dataPoint?.args, refData);

                    SetCreateDataSetField(processedArgsData3);
                    break;
                case "validatedataset":
                    if (verifyDatasetAgainstMultipleValues(refData, dataPoint?.verify)) {
                        store.dispatch(hideAppBusy());
                        return;
                    }
                    break;
                case "logout":
                    authServiceInstance.logout();
                    break;
                case "showloader":
                    store.dispatch(showAppBusy());
                    break;
                case "hideloader":
                    store.dispatch(hideAppBusy());
                    break;
                case "showmenu":
                    let menuItems = null;

                    if (typeof dataPoint?.args?.items === "string")
                        menuItems = GetControlPropertyFromStoreOrRefData(dataPoint?.args?.items, refData);
                    else if (Array.isArray(dataPoint?.args?.items))
                        menuItems = getProcessedArray(dataPoint?.args?.items, refData);
                    console.log("MENU", menuItems)
                    menuItems &&
                        ShowAppMenu(refData?.controlid, menuItems, dataPoint?.args?.layout, dataPoint?.args?.title, refData)
                    break;
                case "shownotification":

                    ShowAppNotification(dataPoint?.args);
                    break;
                default:

            }
        }
    }
    store.dispatch(hideAppBusy());

}

export function getProcessedArgs(args, refData) {
    if (!args) return null;
    if (args instanceof Date) return args;
    let processedArgs = {};

    let argsToProcess = GetControlPropertyFromStoreOrRefData(args, refData);

    Object.keys(argsToProcess).forEach(item => {
        if (typeof args[item] === "string")
            processedArgs = { ...processedArgs, ...{ [item]: GetControlPropertyFromStoreOrRefData(args[item], refData) } };
        else if (Array.isArray(args[item]))
            processedArgs = { ...processedArgs, ...{ [item]: getProcessedArray(args[item], refData) } };
        else if (typeof args[item] === "object")
            processedArgs = { ...processedArgs, ...{ [item]: getProcessedArgs(args[item], refData) } };
        else
            processedArgs = { ...processedArgs, ...{ [item]: args[item] } };

    });

    return processedArgs;
}

function getProcessedArray(args, refData) {
    let processedArgs = [];
    if (!args) return null;
    if (!args.forEach) return args;
    console.log("ARG", args)
    args.forEach(item => {
        if (typeof item === "string")
            processedArgs = [...processedArgs, GetControlPropertyFromStoreOrRefData(item, refData)];
        else if (typeof item === "object") //Todo: Need to consider Array Later
            processedArgs = [...processedArgs, getProcessedArgs(item, refData)];
        else
            processedArgs = [...processedArgs, item];
    });
    return processedArgs;

}

export function GetControlPropertyFromStoreOrRefData(propertyString, refData = null) { //To resolve  property in bracket to select from Redux State Property
    let currentState = propertyString;
    if (propertyString && propertyString.startsWith && propertyString.startsWith("[") && propertyString.endsWith("]")) {
        // propertyString = propertyString.replace(" ", "")
        let prop = propertyString.replace("[", "").replace("]", "")
        if (prop) {
            let funcDetails = [];

            funcs.forEach(t => {
                let currentFuncIndex = 0;
                do {
                    currentFuncIndex = prop.indexOf(t, currentFuncIndex + 1);
                    if (currentFuncIndex > 0) {
                        funcDetails.push({ "index": currentFuncIndex, "oper": t });
                    }
                } while (currentFuncIndex > 0);
            });

            funcDetails = funcDetails.sort(t => t.index).map((t, index) => {
                return { ...t, "index": index }
            });

            // let funcDetails = funcs.map((t) => {

            //     return { "index": prop.indexOf(t), "oper": t };
            // }).filter(t => t.index > 0).sort(t => t.index).map((t, index) => {
            //     return { ...t, "index": index }
            // });
            if (funcDetails.length > 0) { //Bind string contains a  Formula, Find it and process them
                // let propToSplit = "";
                funcDetails.forEach(t => {
                    prop = prop.split(t.oper).join("*^*");
                });
                let propList = prop.split("*^*");

                let aggregate = _getBindData(propList[0], currentState, refData);
                funcDetails.forEach(t => {
                    let secondValue = _getBindData(propList[t.index + 1], currentState, refData);
                    aggregate = dyadicFuncExecutor(t.oper, aggregate, secondValue);

                });
                currentState = aggregate;

            }
            else { //Binding string doesnt have formula, so just process it
                currentState = _getBindData(prop, currentState, refData);
            }
        }
    }

    if (currentState === undefined) currentState = null;
    return currentState;
}

function _getBindData(prop, currentState, refData) {

    let propArray = prop.split(".");
    let dataMode = "state";

    switch (propArray[0]) {
        case "this":  //If binding string start from this, use RefData i.e, local data send by the request
            currentState = refData;
            break;
        case "func": break;  //If binding string is a value function, no need to get the state data, it will be calculate in following lines
        case "local": //get localStorage data(Treat JSON Parse)
            if (propArray.length > 1) {
                currentState = localStorage[propArray[1]] && JSON.parse(localStorage[propArray[1]]);
                propArray[1] = "local";
            }
            break;
        case "session": //get sessionStorage data (Treat JSON Parse)
            if (propArray.length > 1) {
                currentState = JSON.parse(sessionStorage[propArray[1]]);
                propArray[1] = "session";
            }
            break;
        case "raw":   //Raw Text
            dataMode = "raw";
            propArray.shift(); //Rejoin the string excluding the raw keyword and return it
            currentState = propArray.join(".").replace("{chrdot}", ".");
            break; //If this is raw string no need to get a state, instead it will be processed by the loop
        default: //All other, get state from Redux state
            currentState = store.getState();
    }
    // if (propArray[0] !== "func") { //The binding is not from a Value function (like today)
    //     currentState = propArray[0] === "this" ? refData : store.getState();
    // }
    if (dataMode !== "raw") {
        for (let propItem of propArray) {
            if (propItem === "this" || propItem === "local" || propItem === "session") continue; //If binding string starts with a pointer, just ignore the first section
            if (propItem === 'func') {
                dataMode = 'func';
                continue;
            }

            else if (dataMode === "func") {
                currentState = _getFuncValue(propItem);
                break;
            }
            else if (propItem && currentState) {
                currentState = currentState[propItem];
            }
        }
    }
    return currentState;
}

function _getFuncValue(propItem) {
    switch (propItem) {
        case "today":
            return new Date();
        case "todaystarttime":
            var dt = startOfToday()
            return dt;
        case "todayendtime":
            var dt1 = endOfToday()
            return dt1;
        default:
            return null;
    }
}


export function ChangePageDataSetState(datasets) {  //Join Redux States to single object; To avoid calling local setState multiple times; Currently implemented in  factsTaskList component
    let dstate = {};
    for (let dset of datasets) {
        dstate = { ...dstate, ...{ [dset]: store.getState()[dset] } };
    }
    return dstate;
}