import React, { Component } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { ExecuteLayoutEventMethods, GetControlPropertyFromStoreOrRefData } from "../../../General/commonFunctions";
import store from "../../../AppRedux/store";

class FactsDropDown extends Component {
    constructor(props) {
        super(props);
        let _listData = store.getState()[this.props.listdset];

        let textValueFromState = GetControlPropertyFromStoreOrRefData("[" + props.dset + "." + props.bind + "]");
        this.state = {
            textValue: (textValueFromState === null ? "" : textValueFromState),
            listData: _listData
        }
    }

    componentDidMount() {
        this.mounted = true;

        this.unsubscribe = store.subscribe(() => {
            if (this.mounted) {
                let newState = GetControlPropertyFromStoreOrRefData("[" + this.props.dset + "." + this.props.bind + "]");
                if (this.state.textValue !== newState) {
                    if (newState === null) newState = "";
                    this.setState({
                        textValue: newState
                    });

                }
                let newList = store.getState()[this.props.listdset];
                let thisListString = JSON.stringify(this.state.listData);
                let newListString = JSON.stringify(newList);
                if (thisListString !== newListString) {
                    this.setState({
                        listData: newList
                    });
                }
            }
        });
    }

    componentWillUnmount() {
        this.mounted = false;
        if (this.unsubscribe) this.unsubscribe();

    }

    async saveCurrentTextToDataset() {
        await ExecuteLayoutEventMethods([

            {
                exec: "setdatasetfield",
                args: {
                    dset: this.props.dset,
                    fieldname: this.props.bind,
                    data: this.state.textValue
                }
            }
        ]);
    }

    async runCustomOnChangeEvents() {

        await this.saveCurrentTextToDataset();
        await ExecuteLayoutEventMethods(this.props.whenchange, this.state);

    }
    async handleOnChange(e) {
        this.setState({
            textValue: e.target.value
        }, () => {
            this.runCustomOnChangeEvents();
        });

    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(this.state) === JSON.stringify(nextState)) {
            return false;
        } else {
            return true;
        }
    }

    render() {

        return (

            <TextField size="small" {...this.props} select={true} value={this.state.textValue} onChange={(e) => this.handleOnChange(e)}   >
                {this.state.listData?.map((t, index) => {
                    return <MenuItem value={t[this.props.valuefield]} key={index}>{t[this.props.textfield]}</MenuItem>
                })}
            </TextField>

        );
    }
}

export default FactsDropDown;