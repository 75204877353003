import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import React, { Component } from "react";
import { ExecuteLayoutEventMethods, GetControlPropertyFromStoreOrRefData } from "../../../General/commonFunctions";
import store from "../../../AppRedux/store";

class FactsLookup extends Component {
    constructor(props) {
        super(props);
        let textValueFromState = GetControlPropertyFromStoreOrRefData("[" + props.dset + "." + props.bind + "]");
        this.state = {
            textValue: (textValueFromState === null ? "" : textValueFromState)
        }
    }

    ripOffControlSpecificAttributes() {
        const excluded = ["className"];
        return (
            Object.keys(this.props)
                .filter((t) => !excluded.includes(t))
                .reduce((obj, key) => {
                    obj[key] = this.props[key];
                    return obj;
                }, {}));

    }

    componentDidMount() {
        this.unsubscribe = store.subscribe(() => {

            let newState = GetControlPropertyFromStoreOrRefData("[" + this.props.dset + "." + this.props.bind + "]");

            if (this.state.textValue !== newState) {
                this.setState({
                    textValue: newState
                });
            }
        });

    }
    componentWillUnmount() {
        if (this.unsubscribe) this.unsubscribe();
    }

    async handleOnClick(e) {
        await ExecuteLayoutEventMethods(this.props.whenclick);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(this.state) === JSON.stringify(nextState)) {
            return false;
        } else {
            return true;
        }
    }

    render() {
        let newProps = this.ripOffControlSpecificAttributes();

        return (
            <TextField
                className={"facts-lookup " + (this.props.className ?? "")}
                InputProps={{
                    readOnly: true,
                    endAdornment: <InputAdornment position="end">
                        <Icon>{this.props.icon}</Icon>
                    </InputAdornment>
                }}
                variant="outlined" size="small" {...newProps} value={this.state.textValue}
                // onChange={(e) => this.handleOnChange(e)} onBlur={() => this.handleOnBlur()}
                onClick={(e) => this.handleOnClick(e)} />
        )
    }
}

export default FactsLookup; 