import React, { Component } from "react";
import { objectMatchAll, getProcessedArgs, GetControlPropertyFromStoreOrRefData } from "../../../General/commonFunctions";
import store from "../../../AppRedux/store";
import ListViewBubbleTemplate from "../ControlTemplates/ListViewItem/listViewBubbleTemplate";
import ListViewCustomTemplate from "../ControlTemplates/ListViewItem/listViewCustomTemplate";

class FactsList extends Component {


    constructor(props) {
        super(props);
        this.state = {
            currentDS: this.filterDataset(this.getCurrentDataSet())
        };
        this.divRef = React.createRef();
    }

    ripOffControlSpecificAttributes() {
        this.scrolldown = this.props.scrolldown;

        const excluded = ["scrolldown", "content", "datasets", "refData"];
        return (
            Object.keys(this.props)
                .filter((t) => !excluded.includes(t))
                .reduce((obj, key) => {
                    obj[key] = this.props[key];
                    return obj;
                }, {}));

    }

    getCurrentDataSet() {
        let listds = GetControlPropertyFromStoreOrRefData("[" + this.props.datasets[0] + "]");
        // console.log("HHHHH", listds, this.props.datasets[0]);
        return listds;
    }

    filterDataset(listData) {
        if (this.props.filterto && listData) {
            let filterTo = getProcessedArgs(this.props.filterto, this.props.refData);
            if (filterTo) {
                return listData.filter(it => {

                    return objectMatchAll(it, filterTo);
                });
            }
            else return listData;
        }
        else return listData;
    }

    componentDidMount() {
        this.mounted = true;

        if (this.props.datasets && this.props.datasets.length > 0) {
            // this.setState({
            //     currentDS: this.getCurrentDataSet()
            // });
            this.scrollToDown();
            this.unsubscribe = store.subscribe(() => {
                if (this.mounted) {

                    let newDS = this.getCurrentDataSet();
                    let newDSString = JSON.stringify(newDS),
                        currentDSString = JSON.stringify(this.state.currentDS);

                    if (newDSString !== currentDSString) {
                        this.setState({
                            currentDS: this.filterDataset(newDS)
                        });
                        this.scrollToDown();
                    }
                }
            });
        }

    }

    scrollToDown() {
        if (this.scrolldown === true) {
            setTimeout(t => {
                if (this.divRef?.current?.lastChild) {
                    this.divRef.current.lastChild.scrollIntoView({ block: 'start' });
                }
                else this.scrollToDown();
            }, 25);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        this.scrolldown = false;
        if (this.unsubscribe) this.unsubscribe();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(this.state) === JSON.stringify(nextState)) {

            return false;
        } else {

            return true;
        }
    }

    render() {
        // console.log("LVLIST", this.state.currentDS)

        let newProps = this.ripOffControlSpecificAttributes();
        let listArrayToRender = this.convertToArrayIfObject(this.state.currentDS);

        return (
            <div ref={this.divRef}  {...newProps} className={"lvlist " + (newProps?.className ?? "")} >
                {
                    listArrayToRender && listArrayToRender &&
                    (listArrayToRender.map((it, index) => {
                        return this.getContentTemplate(this.props.content, it, index);
                        // let isLastItem = ind >= (this.state.currentDS.length -1)
                    }))

                }
            </div>
        );

    }

    convertToArrayIfObject(currentList) {
        if (currentList && !Array.isArray(currentList)) {
            return Object.keys(this.state.currentDS).map((t) => {
                return { "property": t, "value": currentList[t] }
            })
        }
        return currentList;

    }


    // renderRow(props) {
    //     const { index, style } = props;
    //     let contentTemplate = this.getContentTemplate(this.props.content, this.state.currentDS[index]);

    //     return (
    //         <div key={index} style={style}>
    //             {contentTemplate}
    //         </div>
    //     );
    // }

    getContentTemplate(content, item, index) {
        switch (content.template) {
            case "lvibubble":
                return (<ListViewBubbleTemplate key={index} {...content.props} itemObject={item} />);

            case "custom":
                return (<ListViewCustomTemplate key={index} itemProps={content.props} layout={content.chld} itemObject={item} />);
            default: return (<div {...content.props} />);
        }
    }

}

export default FactsList; 