import React, { Component } from "react";
import AppBarComponent from "./Parts/appBarComponent";
import StorageService from "../Services/storageService";
import { userDetailsStorageKey, userMenuStorageKey, whenAppLoad } from "../General/globals";
import GenericPageComponent from "./Pages/genericPageComponent";
import { Grid, Dialog } from "@material-ui/core";
import store from "../AppRedux/store";
import { hideAppBusy } from "../AppRedux";
import { ExecuteLayoutEventMethods, SetCreateDataSet } from "../General/commonFunctions";
import PageHeaderSection from './Parts/pageHeaderSection';
import AppBarInnerComponent from "./Parts/appBarInnerComponent";
import PageFooterSection from "./Parts/pageFooterSection";


class AuthorizedComponent extends Component {
    constructor(props) {
        super(props);
        let userInfo = new StorageService().getJsonValue(userDetailsStorageKey);
        let _userMenu = new StorageService().getJsonValue(userMenuStorageKey);

        _userMenu = _userMenu?.map(t => {
            let actionObj = JSON.parse(t.action);
            t.action = actionObj;
            return t;
        });

        this.state = {
            // toolbar: this.props.pageInfo?.toolbar,
            // pageLayout: this.props.pageInfo?.layout,
            userMenu: _userMenu
        };
        if (!userInfo || !_userMenu) {

            console.log("Tampered UserInfo! Dispatching Logout...!");
            ExecuteLayoutEventMethods([{ "exec": "logout" }]);
            return;
        }


        // store.dispatch(storeUser(userInfo)); //Redux
        SetCreateDataSet({ "dset": "_userinfo" }, userInfo); //Redux
        SetCreateDataSet({ "dset": "_usermenu" }, _userMenu); //Redux
        ExecuteLayoutEventMethods(whenAppLoad); //Appload from Initjson

    }



    async componentDidMount() {
        this.handleRouting();
        store.dispatch(hideAppBusy());

    }

    async handleRouting() {
        let docNoFromRoute = this.props?.match?.params["permalink"];
        let pageToShow = this.state.userMenu?.filter(t => docNoFromRoute === t.permalink);
        if (pageToShow?.length <= 0) {
            pageToShow = this.state.userMenu?.filter(t => t.homepage && t.homepage.toLowerCase() === "true");
        }
        if (pageToShow?.length > 0) {
            window.location.hash = "#" + pageToShow[0]?.permalink;
            await ExecuteLayoutEventMethods(pageToShow[0]?.action);
        }

    }


    render() {

        return (
            // <ThemeProvider theme={this.theme} >
            <>
                <div className="main-wrap" >
                    <AppBarComponent {...this.props.pageInfo?.appbar} userMenu={this.state.userMenu} /*executeMenuRouting={this.executeMenuRouting.bind(this)} />*/ ></AppBarComponent>
                    <div className="page-wrap" style={this.props.pageInfo?.style}>

                        <PageHeaderSection pageHeader={this.props.pageInfo?.pageheader} />

                        <Grid container className="page-container" >
                            <GenericPageComponent pageInfo={this.props.pageInfo} />
                        </Grid>
                    </div>
                    <PageFooterSection pageFooter={this.props.pageInfo?.pagefooter} userMenu={this.state.userMenu} />

                </div>
                {
                    this.props.popupInfo ?
                        <div className="popup-wrap">
                            {Object.keys(this.props.popupInfo).map((popupName, index) => {
                                let popup = this.props.popupInfo && this.props.popupInfo[popupName];
                                if (popup) {
                                    return (
                                        <Dialog key={index} fullScreen={popup?.fullscreen ?? true} open={true}>
                                            {popup?.appbar ?
                                                (<AppBarInnerComponent {...popup?.appbar} />)
                                                :
                                                (<></>)
                                            }
                                            <PageHeaderSection pageHeader={popup?.pageheader} />

                                            <Grid container className="page-container" >
                                                <GenericPageComponent pageInfo={popup} />

                                            </Grid>
                                        </Dialog>);
                                }
                                else return null;
                            })}
                        </div>
                        : <></>

                }
            </>
            // </ThemeProvider>
        );
    }

}

export default AuthorizedComponent; 