import React, { Component } from "react";
import PureJSComponentFactory from "./Factory/pureJSComponentFactory";

class GenericPageComponent extends Component {

    render() {
        let scaff = new PureJSComponentFactory().scaffoldComponent(this.props.pageInfo?.layout);

        return (<>{scaff}</>);
    }



}

export default GenericPageComponent; 