import { differenceInMilliseconds, startOfToday, addMilliseconds } from "date-fns";
import { format } from "date-fns/esm";
import { getTZPrefix } from "./globals";

const DATEDIFF = "(datediff)", IFNULL = "(ifnull)", FORMAT = "(dtformat)", JOINSTR = "(joinstr)";
export const funcs = [DATEDIFF, IFNULL, FORMAT, JOINSTR];

export function dyadicFuncExecutor(funcName, firstValue, secondValue) {
    switch (funcName) {
        case DATEDIFF:
            return _dateDiffExec(firstValue, secondValue);
        case IFNULL:
            return _ifNullExec(firstValue, secondValue);
        case FORMAT:
            return _formatDateTime(firstValue, secondValue);
        case JOINSTR:
            return (firstValue ?? "") + (secondValue ?? "");
        default:
            return null;
    }
}

function _dateDiffExec(firstValue, secondValue) {
    if (typeof firstValue === "string") firstValue += getTZPrefix();
    if (typeof secondValue === "string") secondValue += getTZPrefix();

    let firstDateValue = Date.parse(firstValue),
        secondDateValue = Date.parse(secondValue);
    if (!firstDateValue) firstDateValue = new Date();
    if (!secondDateValue) secondDateValue = new Date();
    let millis = differenceInMilliseconds(secondDateValue, firstDateValue);
    return addMilliseconds(startOfToday(), millis);
}

const _ifNullExec = (firstValue, secondValue) => firstValue ?? secondValue;  //Return second value if first value is null



function _formatDateTime(firstValue, formatString) {
    try {
        if (typeof firstValue === "string") firstValue += getTZPrefix();
        let dateValue = Date.parse(firstValue);
        if (!dateValue) dateValue = new Date();
        if (!formatString) formatString = "dd-MMM-yyyy";
        return format(dateValue, formatString);
    }
    catch{
        return firstValue;
    }
}