import {
    STORE_DATASET, STORE_DATASET_FIELD, MERGE_OBJECT_DATASET,
    STORE_USER, AUTH_LOGIN, AUTH_LOGOUT,
    SHOW_APP_BUSY, HIDE_APP_BUSY
} from "./pageDsActionTypes";

const initialState = {
};

const pageDsReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_DATASET:
            return {
                ...state,
                [action.payload.dataSetName]: action.payload.data
            };
        case MERGE_OBJECT_DATASET:
            return {
                ...state,
                [action.payload.dataSetName]: { ...state[action.payload.dataSetName], ...action.payload.data }
            };
        case STORE_DATASET_FIELD:
            let fieldDataset = { ...state[action.payload.dataSetName], [action.payload.fieldName]: action.payload.data };
            if (!action.payload.data)
                delete fieldDataset[action.payload.fieldName];

            return {
                ...state,
                [action.payload.dataSetName]: fieldDataset
            };
        //Login && User Details Below
        case AUTH_LOGIN:
            return {
                ...state,
                "login": {
                    ...state["login"],
                    isAuthenticated: true
                }
            };
        case AUTH_LOGOUT:
            return {
                "login": {
                    isAuthenticated: false
                }
            };
        case SHOW_APP_BUSY:
            return {
                ...state,
                "login": {
                    ...state["login"],
                    isAppBusy: true
                }
            };
        case HIDE_APP_BUSY:
            return {
                ...state,
                "login": {
                    ...state["login"],
                    isAppBusy: false
                }
            };
        case STORE_USER:
            return {
                ...state,
                "login": {
                    ...state["login"],
                    details: action.payload
                }
            };

        default: return state;

    }
}

export default pageDsReducer; 