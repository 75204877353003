import React, { Component } from "react";

import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import ListViewTaskTemplate from "../ControlTemplates/ListViewItem/listViewTaskTemplate";
import './factsSwipeList.css';
import store from "../../../AppRedux/store";
import { ExecuteLayoutEventMethods, objectMatchAll, GetControlPropertyFromStoreOrRefData } from '../../../General/commonFunctions'
import Icon from "@material-ui/core/Icon";
import ListViewCustomTemplate from "../ControlTemplates/ListViewItem/listViewCustomTemplate";

class FactsSwipeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listds: []
        };
    }

    ripOffControlSpecificAttributes() {

        const excluded = ["dset", "filterto", "refData", "content", "left", "right", "itemprops"];
        return (
            Object.keys(this.props)
                .filter((t) => !excluded.includes(t))
                .reduce((obj, key) => {
                    obj[key] = this.props[key];
                    return obj;
                }, {}));
    }

    filterDataset(listData) {
        if (this.props.filterto && listData) {
            let filterTo = GetControlPropertyFromStoreOrRefData(this.props.filterto, this.props.refData);
            if (filterTo) {
                return listData.filter(it => {
                    return objectMatchAll(it, filterTo);
                });
            }
            else return listData;
        }
        else return listData;
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.dset) {
            let listData = store.getState()[this.props.dset];

            this.setState({
                listds: this.filterDataset(listData)
            });


            this.unsubscribe = store.subscribe(() => {
                if (this.mounted) {

                    let listData = store.getState()[this.props.dset];
                    let filteredData = this.filterDataset(listData);
                    if (JSON.stringify(filteredData) !== JSON.stringify(this.state.listds)) {
                        this.setState({
                            listds: filteredData
                        });
                    }
                }
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        if (this.unsubscribe) this.unsubscribe();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(this.state) === JSON.stringify(nextState)) {
            return false;
        } else {
            return true;
        }
    }

    render() {
        let newProps = this.ripOffControlSpecificAttributes();

        return (<div {...newProps}>
            {
                this.state.listds && this.state.listds?.length > 0 ?
                    <SwipeableList threshold={0.25}  >
                        {
                            this.state.listds.map &&
                            this.state.listds.map((item, index) => {

                                let contentTemplate = this.getContentTemplate(this.props.content, item, index);
                                return (
                                    <div key={index} style={this.props.itemprops?.style}>
                                        <SwipeableListItem
                                            swipeRight={this.props.left && {
                                                content:
                                                    <div className="swplist left" style={this.props.left?.props?.style}>
                                                        <Icon>{this.props.left.icon}</Icon>
                                                        <span>{this.props.left.text}</span>
                                                    </div>,
                                                action: async () => {
                                                    await ExecuteLayoutEventMethods(this.props.left.props.whenswipe, item);
                                                }
                                            }}
                                            swipeLeft={this.props.right && {
                                                content:
                                                    <div className="swplist right" style={this.props.right?.props?.style}>
                                                        <span>{this.props.right.text}</span>
                                                        <Icon>{this.props.right.icon}</Icon>
                                                    </div>,
                                                action: async () => {
                                                    await ExecuteLayoutEventMethods(this.props.right.props.whenswipe, item);
                                                }
                                            }}
                                        // onSwipeProgress={progress => console.info(`Swipe progress: ${progress}%`)}
                                        >
                                            {contentTemplate}
                                        </SwipeableListItem>
                                    </div>

                                );
                            })

                        }
                    </SwipeableList>
                    :
                    <div>{this.props?.emptytext}</div>
            }
        </div>);
    }

    getContentTemplate(content, item, index) {
        switch (content.template) {
            case "lvitask":
                return (<ListViewTaskTemplate key={index} itemProps={content.props} itemObject={item} />);
            case "custom":
                return (<ListViewCustomTemplate key={index} itemProps={content.props} layout={content.chld} itemObject={item} />);

            default: return (<div {...content.props} />);
        }
    }
}

export default FactsSwipeList;