import React, { Component } from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Button, Switch, FormControlLabel } from "@material-ui/core";
import { authServiceInstance, userDetailsStorageKey, applicationTitle, userMenuStorageKey, loginImage, loginTitle, loginDivStyle } from "../General/globals";
import StorageService from "../Services/storageService";
import store from "../AppRedux/store";
import { authLogin, showAppBusy, hideAppBusy } from "../AppRedux";
import { ShowSnackBar } from "../General/globalFunctions";
import AppUpdate from "../AppUpdate";


class LoginComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loginName: '',
            password: '',
            rememberMe: false

        };
        document.title = applicationTitle;
    }

    handleInputChange(e) {
        let stateValue = e.target.value;
        if (e.target.type === "checkbox") {
            stateValue = e.target.checked;
        }
        this.setState({
            [e.target.id]: stateValue
        });
    }

    async loadUserSpecificMenu(_userrole) {
        let _userMenu = await authServiceInstance.loadUserMenu({ userrole: _userrole });
        new StorageService().setJsonValue(userMenuStorageKey, _userMenu);

        // if (userDetails && userDetails.length > 0) {
        //     new StorageService().setJsonValue(userDetailsStorageKey, userDetails[0]);
        //     return true;
        // }
        // return false;
    }

    async doSignIn(e) {
        try {
            e.preventDefault();
            store.dispatch(showAppBusy());
            await authServiceInstance.login(this.state.loginName, this.state.password, this.state.rememberMe);
            let authOK = await authServiceInstance.verifyLogin();
            if (authOK) {
                let _userDetails = await this.getCurrentUserDetails();
                if (!_userDetails) throw new Error("No Such User!");
                await this.loadUserSpecificMenu(_userDetails.userrole);

                store.dispatch(authLogin()); //Redux
                store.dispatch(hideAppBusy());
                return;
            }
        }
        catch{ }
        store.dispatch(hideAppBusy()); //Hide loader if the authentication failed with an errorx

        ShowSnackBar("error", 'Login Failed!');
    }

    async getCurrentUserDetails() {
        let userDetails = await authServiceInstance.loadUserDetails();

        if (userDetails && userDetails.length > 0) {
            new StorageService().setJsonValue(userDetailsStorageKey, userDetails[0]);
            return userDetails[0];
        }
        return null;
    }

    render() {
        return (
            <form onSubmit={this.doSignIn.bind(this)}>
                <Card className="login-card" variant="outlined">
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} className="login-head" style={loginDivStyle}  >
                                {loginImage &&
                                    <img src={loginImage} alt="Logo" />}
                                {loginTitle &&
                                    <h2>{loginTitle}</h2>}
                            </Grid>
                            <Grid item xs={12} >
                                <TextField id="loginName" label="User Name" variant="outlined"
                                    onChange={this.handleInputChange.bind(this)}
                                    value={this.state.loginName} fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField id="password" label="Password" variant="outlined"
                                    onChange={this.handleInputChange.bind(this)}
                                    value={this.state.password} type="password" fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel control={
                                    <Switch id="rememberMe" checked={this.state.rememberMe}
                                        onChange={this.handleInputChange.bind(this)} />
                                }
                                    label="Remember Me" />

                            </Grid>
                            <Grid item xs={6} >
                                <Button type="submit" variant="contained" size="large" color="primary" fullWidth  >Sign In</Button>
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            </form>
        );
    }

}

export default LoginComponent; 