import React, { Component } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { HideSnackBar } from "../../../General/globalFunctions";

class AppSnackBar extends Component {

    onSnackClose() {
        HideSnackBar();
    }

    render() {

        return (
            <Snackbar open={this.props.snackInfo?.open} autoHideDuration={this.props.snackInfo?.duration ?? 5000} onClose={this.onSnackClose.bind(this)} >
                <MuiAlert elevation={6} variant="filled" severity={this.props.snackInfo?.type} onClose={this.onSnackClose.bind(this)} >
                    {this.props.snackInfo?.message}
                </MuiAlert>
            </Snackbar>
        );
    }
}

export default AppSnackBar;