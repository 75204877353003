import { AppBar, Toolbar, IconButton, Typography, Icon } from "@material-ui/core";
import React, { Component } from "react";
import { ExecuteLayoutEventMethods, GetControlPropertyFromStoreOrRefData } from "../../General/commonFunctions";
import PureJSComponentFactory from "../Pages/Factory/pureJSComponentFactory";

class AppBarInnerComponent extends Component {

    constructor(props) {
        super(props);
        this.title = GetControlPropertyFromStoreOrRefData(props.title);

    }
    async handleBackClick(clickArgs) {
        await ExecuteLayoutEventMethods(
            [
                {
                    "exec": "setdatasetfield",
                    "args": {
                        "dset": "popupinfo",
                        "fieldname": this.props.popupName,
                        "data": null
                    }
                },
                ...clickArgs]);

    }


    render() {
        let scaff = new PureJSComponentFactory().scaffoldComponent(this.props.chld);

        return (
            <>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => this.handleBackClick(this.props?.whenbackclick)}   >
                            <Icon>arrow_back</Icon>
                        </IconButton>
                        <Typography variant="h6" className="flex-grow" >{this.title}</Typography>
                        {scaff}
                    </Toolbar>
                </AppBar>
            </>
        );
    }

}

export default AppBarInnerComponent;