import TextField from "@material-ui/core/TextField";
import React, { Component } from "react";
import { ExecuteLayoutEventMethods, GetControlPropertyFromStoreOrRefData } from "../../../General/commonFunctions";
import store from "../../../AppRedux/store";

class FactsEntry extends Component {
    constructor(props) {
        super(props);
        let textValueFromState = GetControlPropertyFromStoreOrRefData("[" + props.dset + "." + props.bind + "]");
        this.state = {
            textValue: (textValueFromState === null ? "" : textValueFromState)
        }
        this.finalValue = (textValueFromState === null ? "" : textValueFromState);

    }

    componentDidMount() {
        this.mounted = true;
        this.unsubscribe = store.subscribe(() => {
            if (this.mounted) {

                let newState = GetControlPropertyFromStoreOrRefData("[" + this.props.dset + "." + this.props.bind + "]");
                if (this.finalValue !== newState) {

                    if (newState === null) newState = "";
                    this.finalValue = newState;
                    this.setState({
                        textValue: newState
                    });

                }
            }
        });

    }



    componentWillUnmount() {
        this.mounted = false;
        if (this.unsubscribe) this.unsubscribe();

    }

    async handleOnBlur(e) {
        if (this.props.dset && this.props.bind)
            await this.saveCurrentTextToDataset();
        await ExecuteLayoutEventMethods(this.props.whenblur, this.state);
    }
    async saveCurrentTextToDataset() {
        this.finalValue = this.state.textValue;
        await ExecuteLayoutEventMethods([

            {
                exec: "setdatasetfield",
                args: {
                    dset: this.props.dset,
                    fieldname: this.props.bind,
                    data: this.state.textValue
                }
            }
        ]);
    }
    async runCustomOnChangeEvents() {
        await this.saveCurrentTextToDataset();
        if (this.props.whenchange) await ExecuteLayoutEventMethods(this.props.whenchange, this.state);

    }
    async handleOnChange(e) {
        this.finalValue = e.target.value;
        this.setState({
            textValue: e.target.value
        }, () => {

            this.runCustomOnChangeEvents();
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(this.state) === JSON.stringify(nextState)) {
            return false;
        } else {
            return true;
        }
    }

    render() {
        console.log("ENTRY REND")
        return (
            <TextField variant="outlined" size="small" {...this.props} value={this.state.textValue} onChange={(e) => this.handleOnChange(e)} onBlur={() => this.handleOnBlur()}
            />
        )
    }
}

export default FactsEntry; 