import AuthService from "../Services/authService";
import PageService from "../Services/pageService";

export let appSWRegistration;
export let appNotifPermission;

export let apiRoot;//= 'https://apiquery.factserp.com/'; //'http://localhost:50642/' ; 
export let authPath;//= 'oauth/token';
export let procPath;//= 'api/proc';

export let uploadRoot;//= 'https://apiquery.factserp.com/'; //'http://localhost:50642/' ; 
export let uploadPath;//= 'api/file';

export let authBody;// =

export const appFileReader = new FileReader();
// {
//     grant_type: "client_credentials",
//     hash_method: "1",
//     job_id: "1409"  //"1409(DICETEK)" // "0378(CSH)" 
// };

export let authServiceInstance;
export let pageServiceInstance;

export const authStorageKey = 'aCh';
export const userDetailsStorageKey = 'uD';
export const userMenuStorageKey = "uMe";

export let applicationTitle = "FactsPWA";

export let loginImage;//= "res/loginpage.png";
export let loginTitle;//= null;
export let loginDivStyle;//= { "textAlign": "center" };

export let appTheme;

export let whenAppLoad;

export let appTimerExecs;

export let serverPWAVersion;

export function initGlobalValues(appOptions) {
    if (appOptions?.ver) serverPWAVersion = appOptions?.ver;

    if (appOptions?.title) applicationTitle = appOptions?.title;
    if (appOptions?.service?.url) apiRoot = appOptions?.service?.url;
    if (appOptions?.service?.auth) authPath = appOptions?.service?.auth;
    if (appOptions?.service?.proc) procPath = appOptions?.service?.proc;
    if (appOptions?.service?.authbody) authBody = appOptions?.service?.authbody;

    if (appOptions?.upload?.url) uploadRoot = appOptions?.upload?.url;
    if (appOptions?.upload?.path) uploadPath = appOptions?.upload?.path;
    if (appOptions?.login?.logo) loginImage = appOptions?.login?.logo;
    if (appOptions?.login?.title) loginTitle = appOptions?.login?.title;
    if (appOptions?.login?.align) loginDivStyle = { "textAlign": appOptions?.login?.align };
    if (appOptions?.theme) appTheme = appOptions?.theme;

    if (appOptions?.whenload) whenAppLoad = appOptions?.whenload;

    if (appOptions?.whentimer) appTimerExecs = appOptions?.whentimer;

    authServiceInstance = new AuthService(authPath, authBody);
    pageServiceInstance = new PageService(authBody);
}


export function setSWRegistration(registration) {
    appSWRegistration = registration;
}

export function setNotifPermission(permission) {
    appNotifPermission = permission;

}

export const getTZPrefix = () => { //GetTimezonePrefix
    let tz = new Date().getTimezoneOffset();
    tz = Math.abs(tz);
    return ("+" + (tz / 60).toFixed(0).padStart(2, 0) + ":" + (tz % 60).toFixed(0).padStart(2, 0));

}