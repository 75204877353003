import React from 'react';
import { Grid, ButtonGroup, Icon } from '@material-ui/core';

import FactsDataGrid from '../../Parts/ControlsERP/factsDataGrid';

import FactsExpansionPanel from '../../Parts/Controls/factsExpansionPanel';
import FactsSwipeList from '../../Parts/Controls/factsSwipeList';
import FactsButton from '../../Parts/Controls/factsButton';
import FactsEntry from '../../Parts/Controls/factsEntry';
import FactsFAB from '../../Parts/Controls/factsFAB';
import FactsDropDown from '../../Parts/Controls/factsDropDown';
import FactsVirtualList from '../../Parts/Controls/factsVirtualList';
import { GetControlPropertyFromStoreOrRefData, getProcessedArgs } from '../../../General/commonFunctions';
import FactsList from '../../Parts/Controls/factsList';
import FactsLabel from '../../Parts/Controls/factsLabel';
import FactsIconButton from '../../Parts/Controls/factsIconButton';
import FactsDatePicker from '../../Parts/Controls/factsDatePicker';
import FactsLookup from '../../Parts/Controls/factsLookup';
import FactsTimePicker from '../../Parts/Controls/factsTimePicker';
import FactsDevList from '../../Parts/Controls/factsDevList';
import FactsClickRecognizer from '../../Parts/Controls/factsClickRecognizer';
import FactsFileButton from '../../Parts/Controls/factsFileButton';
import FactsImage from '../../Parts/Controls/factsImage';
import FactsAvatar from '../../Parts/Controls/factsAvatar';
import FactsHidden from '../../Parts/Controls/factsHidden';
import FactsPDFPreview from '../../Parts/Controls/factsPDFPreview';
import FactsERPChart from '../../Parts/ControlsERP/factsERPChart';
import FactsCanvas from '../../Parts/Controls/factsCanvas';

class PureJSComponentFactory {

    scaffoldComponent(layout, refData = null) {
        let layoutArray = layout;
        if (typeof layout === "string") layoutArray = GetControlPropertyFromStoreOrRefData(layout, refData);

        if (layoutArray && Array.isArray(layoutArray)) {
            return (
                layoutArray.map(
                    (ctrl, index) => {
                        let ctrlChildren = null;
                        if (ctrl.chld) {

                            if (Array.isArray(ctrl.chld)) {
                                ctrlChildren = this.scaffoldComponent(ctrl.chld, refData);
                            }
                            else if (typeof ctrl.chld === "string") {
                                ctrlChildren = GetControlPropertyFromStoreOrRefData(ctrl.chld, refData);

                                if (Array.isArray(ctrlChildren)) {
                                    ctrlChildren = this.scaffoldComponent(ctrlChildren, refData);
                                }
                            }
                        }
                        let controlType = this._getControlTypeFromKey(ctrl.type);
                        let styleProps = {};
                        if (ctrl.props?.style && ctrl.props?.resolvestyles) {
                            styleProps = { "resolvestyles": undefined, style: getProcessedArgs(ctrl.props.style, refData) };
                        }
                        let ctrlProps = ctrl.props ? { key: index, ...ctrl.props, ...styleProps } : { key: index, ...styleProps };
                        if (ctrlProps.includerefdata) ctrlProps = { refData: refData, ...ctrlProps, "includerefdata": undefined };
                        return React.createElement(controlType, ctrlProps, ctrlChildren)
                    }
                )
            );
        }
    }

    // _resolvestyles(styles, refData) { //TODO Need to refactor; Now using getProcessedArgs
    //     let newStyles = {};
    //     Object.keys(styles).forEach(t => {
    //         newStyles = {
    //             ...newStyles,
    //             [t]: GetControlPropertyFromStoreOrRefData(styles[t], refData)
    //         };

    //     });

    //     return newStyles;
    // }

    _getControlTypeFromKey(elementKey) {
        switch (elementKey.toLowerCase()) {
            //Charts
            case "echart": return (FactsERPChart);

            case "entry": return (FactsEntry);
            case "dtpick": return (FactsDatePicker);
            case "tmpick": return (FactsTimePicker);
            case "lbl": return (FactsLabel);
            case "imgr": return (FactsImage);
            case "canv": return (FactsCanvas);
            case "pdf": return (FactsPDFPreview);
            case "sect": return (Grid);
            case "ftbl": return (FactsDataGrid);
            case "expanel": return (FactsExpansionPanel);
            case "swplist": return (FactsSwipeList);
            case "btngroup": return (ButtonGroup);
            case "hide": return (FactsHidden);
            case "btn": return (FactsButton);
            case "filebtn": return (FactsFileButton);
            case "icbtn": return (FactsIconButton);
            case "fab": return (FactsFAB);
            case "icon": return (Icon);
            case "ddl": return (FactsDropDown);
            case "lkup": return (FactsLookup);
            case "dxlist": return (FactsDevList);
            case "vlist": return (FactsVirtualList);
            case "list": return (FactsList);
            case "click": return (FactsClickRecognizer);
            case "favatar": return (FactsAvatar);

            default: return elementKey;
        }

    }

}

export default PureJSComponentFactory; 