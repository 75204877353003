import React, { Component } from "react";
import PureJSComponentFactory from "../../../Pages/Factory/pureJSComponentFactory";
import { ExecuteLayoutEventMethods } from "../../../../General/commonFunctions";


class ListViewCustomTemplate extends Component {

    async handleClick() {

        await ExecuteLayoutEventMethods(this.props.itemProps?.whenclick, this.props.itemObject);

    }

    render() {
        let scaff = new PureJSComponentFactory().scaffoldComponent(this.props.layout, this.props.itemObject);

        return (
            <div {...this.props?.itemProps} whenclick={undefined} onClick={this.handleClick.bind(this)} >
                {scaff}
            </div>
        );
    }


}

export default ListViewCustomTemplate; 